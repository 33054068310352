<template>
  <page>
    <div slot="headerRight">
      <el-button
        v-if="btnShow && btnShow.add"
        type="primary"
        @click="
          visible = true
          currentItem = null
          parentId = ''
        "
        icon="el-icon-plus"
        >{{ $t('newadd') }}</el-button
      >
    </div>

    <div class="midBox">
      <el-tree
        ref="tree"
        class="tree"
        :data="treeData"
        node-key="id"
        default-expand-all
        :expand-on-click-node="false"
        :props="{
          children: 'children',
          label: 'orgName'
        }"
        highlight-current
        @node-click="letfTreeNodeClick"
        v-loading="loading"
      >
        <div class="custom-tree-node" slot-scope="{ node, data }">
          <el-tooltip :open-delay="3000" effect="light" :content="data.orgName" placement="left">
            <div class="name">
              {{ data.orgName }}
            </div>
          </el-tooltip>
          <!-- 全部分组不可移动 -->
          <div>
            <el-dropdown class="more" @command="commandHandler($event, data)">
              <span>
                ●●●
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="add" v-if="btnShow && btnShow.add">{{
                  $t('newadd')
                }}</el-dropdown-item>
                <el-dropdown-item command="edit" v-if="btnShow && btnShow.edit">{{
                  $t('edit')
                }}</el-dropdown-item>
                <!-- 没有下级的才可以删除 -->
                <el-dropdown-item
                  command="delete"
                  v-if="
                    (!data.children || (data.children && !data.children.length)) &&
                      (!data.spList || (data.spList && !data.spList.length)) &&
                      btnShow &&
                      btnShow.delete
                  "
                  >{{ $t('delete') }}</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </el-tree>
      <div class="table-wrap">
        <el-table :data="tableData" border v-loading="tableLoading" height="100%">
          <el-table-column show-overflow-tooltip prop="spId" :label="$t('deviceList.customerId')" />
          <el-table-column
            show-overflow-tooltip
            prop="name"
            :label="$t('serviceManagement.customerName')"
          />
          <el-table-column
            show-overflow-tooltip
            prop="orgName"
            :label="$t('organManagement.organ')"
          />
        </el-table>
      </div>
    </div>
    <AddModal
      @getData="replaceData"
      :parentId="parentId"
      :currentItem="currentItem"
      :visible.sync="visible"
      v-if="visible"
    />
  </page>
</template>

<script>
import watchParamsGetData from '@/mixins/watchParamsGetData'
import AddModal from './components/AddModal'
export default {
  mixins: [watchParamsGetData],
  components: {
    AddModal
  },
  data() {
    return {
      tableLoading: false,
      loading: false,
      visible: false,
      parentId: '',
      currentItem: {},
      tableData: [],
      treeData: []
    }
  },
  created() {},
  methods: {
    async getData() {
      try {
        this.loading = true
        const res = await this.$api.systemManagement.orgTree({})
        const list = res.result || []
        this.treeData = list || []
        this.$nextTick(() => {
          let defaultItem = list.length > 0 ? list[0] : {}
          this.$refs['tree'].setCurrentKey(defaultItem.id || '')
          if (defaultItem.id) {
            this.getOrgAllSp(defaultItem.id)
          }
        })
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async getOrgAllSp(orgId) {
      try {
        this.tableLoading = true
        const res = await this.$api.systemManagement.getOrgAllSp({ orgId })
        this.tableData = res.result || []
      } catch (e) {
        this.tableData = []
        console.log(e)
      } finally {
        this.tableLoading = false
      }
    },
    letfTreeNodeClick(data) {
      this.getOrgAllSp(data.id)
    },
    commandHandler(command, data) {
      if (command === 'add') {
        this.visible = true
        this.currentItem = null
        this.parentId = data.id
      } else if (command === 'edit') {
        this.visible = true
        this.currentItem = data
      } else if (command === 'delete') {
        this.delHandler(data)
      }
    },
    async delHandler(row) {
      await this.$confirm(this.$t('organManagement.deleteThisOrgan'), this.$t('tips'), {
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel')
      })

      try {
        this.loading = true
        await this.$api.systemManagement.orgDelete({ id: row.id })
        this.$message.success(this.$t('deleteSuccess'))
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }

      this.getData()
    }
  }
}
</script>

<style lang="scss" scoped>
.midBox {
  display: flex;
  height: 100%;
  overflow: hidden;
  .tree {
    width: 20%;
    min-width: 300px;
    margin-right: 10px;
    height: 100%;
    overflow: auto;
    border: 1px solid #ebeef5;
    ::v-deep .el-tree-node__content {
      height: 32px;
      font-size: 14px;
    }
    .custom-tree-node {
      width: calc(100% - 24px);
      display: flex;
      justify-content: space-between;
      .name {
        width: calc(100% - 24px);
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .more {
        margin-right: 8px;
        display: none;
      }
      &:hover {
        .more {
          display: inline-block;
        }
      }
    }
  }
}
</style>
