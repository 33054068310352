<template>
  <el-dialog
    v-elDragDialog
    width="700px"
    :title="
      isCreated ? $t('serviceManagement.newCustomers') : $t('serviceManagement.editCustomers')
    "
    :close-on-click-modal="false"
    :visible.sync="show"
    class="add-service"
  >
    <el-form
      :model="form"
      :rules="rules"
      size="small"
      ref="form"
      label-width="100px"
      v-loading="loading"
    >
      <el-form-item :label="$t('serviceManagement.customerName')" prop="name">
        <el-input
          v-model.trim="form.name"
          :placeholder="$t('serviceManagement.enterCustomerName')"
        />
      </el-form-item>

      <el-form-item :label="$t('serviceManagement.contacts')" prop="contactName">
        <el-input
          v-model.trim="form.contactName"
          :placeholder="$t('serviceManagement.enterContact')"
        />
      </el-form-item>

      <el-form-item :label="$t('serviceManagement.email')" prop="phone">
        <el-input v-model.trim="form.phone" :placeholder="$t('serviceManagement.email')" />
      </el-form-item>
      <el-form-item :label="$t('organManagement.organ')" prop="orgId">
        <SelectOrgan
          v-model="form.orgId"
          :placeholder="$t('placeholder')"
          style="width: 100%;"
          :disabled="orgDisabled"
        />
        <div style="color: red" v-show="orgDisabled">
          {{ $t('serviceManagement.orgSelectTips') }}
        </div>
      </el-form-item>

      <el-form-item :label="$t('serviceManagement.customerProfile')">
        <el-input
          type="textarea"
          rows="4"
          resize="none"
          v-model="form.description"
          :placeholder="$t('serviceManagement.enterCustomerProfile')"
        />
      </el-form-item>

      <el-form-item :label="$t('serviceManagement.deadline')">
        <el-date-picker
          :disabled="longTime"
          v-model="form.expirationDate"
          type="date"
          :placeholder="$t('serviceManagement.selectDeadline')"
          align="right"
          :picker-options="pickerOptions"
        />

        <el-checkbox
          style="margin-left:10px"
          v-model="longTime"
          :label="$t('loginPage.longTerm')"
          :indeterminate="false"
        />
      </el-form-item>

      <el-form-item :label="$t('serviceManagement.deviceUpperLimit')" prop="deviceMax">
        <el-input
          :disabled="confine"
          style="width:220px"
          v-model.trim="form.deviceMax"
          maxlength="5"
          @input="form.deviceMax = form.deviceMax.replace(/[^\d]/g, '')"
          :placeholder="$t('serviceManagement.enterDeviceUpperLimit')"
        />

        <el-checkbox
          style="margin-left:10px"
          v-model="confine"
          :label="$t('serviceManagement.unlimited')"
          :indeterminate="false"
        />
      </el-form-item>

      <!-- <el-form-item :label="$t('serviceManagement.freeSpace')">
        <el-input
          :disabled="confine2"
          style="width:198px"
          maxlength="6"
          v-model.trim="form.availableSpace"
          @input="form.availableSpace = form.availableSpace.replace(/[^\d]/g, '')"
          :placeholder="$t('serviceManagement.enterFreeSpace')"
        />
        &nbsp;G

        <el-checkbox
          style="margin-left:10px"
          v-model="confine2"
          :label="$t('serviceManagement.unlimited')"
          :indeterminate="false"
        />
      </el-form-item> -->
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="submitForm(form)" v-loading="btnLoading">{{
        $t('save')
      }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'
export default {
  mixins: [dialogCommonParams],
  components: {},
  props: ['currentSpNames'],
  data() {
    return {
      loading: false,
      btnLoading: false,
      form: {
        name: '',
        contactName: '',
        phone: '',
        description: '',
        expirationDate: +new Date() + 3600 * 1000 * 24,
        deviceMax: '',
        availableSpace: '',
        orgId: ''
      },
      confine: false,
      confine2: false,
      longTime: false,
      orgDisabled: false, //机构是否禁止编辑
      rules: {
        name: [{ required: true, message: this.$t('serviceManagement.enterCustomerName') }],
        contactName: [{ required: true, message: this.$t('serviceManagement.enterContact') }],
        description: [
          { required: true, message: this.$t('serviceManagement.enterCustomerProfile') }
        ],
        orgId: [{ required: true, message: this.$t('organManagement.selectOrganTip') }],
        phone: [
          {
            required: true,
            trigger: 'blur',
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error(this.$t('serviceManagement.enterEmail')))
              } else {
                if (
                  !this.$utils.isPhone(value) &&
                  !this.$utils.isTel(value) &&
                  !this.$utils.isEMail(value)
                ) {
                  return callback(new Error(this.$t('serviceManagement.correctContactInfo')))
                } else {
                  callback()
                }
              }
            }
          }
        ]
      },
      pickerOptions: {
        disabledDate(time) {
          var d = new Date()
          return time.getTime() < d.getTime()
        },
        shortcuts: [
          {
            text: '7' + this.$t('loginPage.day'),
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() + 3600 * 1000 * 24 * 7)
              picker.$emit('pick', date)
            }
          },
          {
            text: '15' + this.$t('loginPage.day'),
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() + 3600 * 1000 * 24 * 15)
              picker.$emit('pick', date)
            }
          },
          {
            text: '30' + this.$t('loginPage.day'),
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() + 3600 * 1000 * 24 * 30)
              picker.$emit('pick', date)
            }
          },
          {
            text: '90' + this.$t('loginPage.day'),
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() + 3600 * 1000 * 24 * 90)
              picker.$emit('pick', date)
            }
          },
          {
            text: '1' + this.$t('loginPage.year'),
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() + 3600 * 1000 * 24 * 365)
              picker.$emit('pick', date)
            }
          }
        ]
      }
    }
  },
  created() {
    if (!this.isCreated) {
      this.checkSpUser()
      Object.keys(this.form).forEach(key => {
        this.form[key] = this.currentItem[key]
      })

      if (this.currentItem.deviceMax === -1) {
        this.confine = true
        this.form.deviceMax = ''
      }

      if (this.currentItem.availableSpace === -1) {
        this.confine2 = true
        this.form.availableSpace = ''
      }

      if (!this.currentItem.expirationDate) {
        this.longTime = true
      }
    }
  },
  methods: {
    async checkSpUser() {
      try {
        this.loading = true
        const res = await this.$api.systemManagement.checkSpUser({ spId: this.currentItem.spId })
        this.orgDisabled = !res.result
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    submitForm(data) {
      const params = { ...data }

      this.$refs.form.validate(async valid => {
        if (valid) {
          if (this.currentSpNames.includes(params.name) && this.isCreated) {
            this.$message.error(this.$t('serviceManagement.changeCustomerName'))
            return
          }

          this.btnLoading = true

          if (!this.isCreated) {
            params.spId = this.currentItem.spId
          }

          if (this.confine || !params.deviceMax) {
            params.deviceMax = -1
          }

          if (this.confine2 || !params.availableSpace) {
            params.availableSpace = -1
          }

          if (this.longTime) {
            params.expirationDate = null
          }

          if (params.expirationDate) {
            params.expirationDate = new Date(params.expirationDate)
          } else {
            params.expirationDate = null
          }

          try {
            this.isCreated
              ? await this.$api.systemManagement.spAdd(params)
              : await this.$api.systemManagement.spUpdate(params)
            this.$message.success(this.isCreated ? this.$t('newSuccess') : this.$t('editSuccess'))
            this.$emit('getData')
            this.show = false
          } catch (e) {
          } finally {
            this.btnLoading = false
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.add-service {
  ::v-deep .el-form {
    width: 500px;
    margin: 0 auto;
  }
}
</style>
