var render = function render(){var _vm=this,_c=_vm._self._c;return _c('page',[_c('div',{attrs:{"slot":"headerRight"},slot:"headerRight"},[(_vm.btnShow && _vm.btnShow.add)?_c('el-button',{attrs:{"type":"primary","icon":"el-icon-plus"},on:{"click":function($event){_vm.visible = true
        _vm.currentItem = null
        _vm.parentId = _vm.currentMerchantOrgId}}},[_vm._v(_vm._s(_vm.$t('newadd')))]):_vm._e()],1),_c('div',{staticClass:"midBox"},[_c('el-tree',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:"tree",staticClass:"tree",attrs:{"data":_vm.treeData,"node-key":"id","default-expand-all":"","expand-on-click-node":false,"props":{
        children: 'children',
        label: 'orgName'
      },"highlight-current":""},on:{"node-click":_vm.letfTreeNodeClick},scopedSlots:_vm._u([{key:"default",fn:function({ node, data }){return _c('div',{staticClass:"custom-tree-node"},[_c('el-tooltip',{attrs:{"open-delay":3000,"effect":"light","content":data.orgName,"placement":"left"}},[_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(data.orgName)+" ")])]),_c('div',[_c('el-dropdown',{staticClass:"more",on:{"command":function($event){return _vm.commandHandler($event, data)}}},[_c('span',[_vm._v(" ●●● ")]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[(_vm.btnShow && _vm.btnShow.add)?_c('el-dropdown-item',{attrs:{"command":"add"}},[_vm._v(_vm._s(_vm.$t('newadd')))]):_vm._e(),(_vm.btnShow && _vm.btnShow.edit)?_c('el-dropdown-item',{attrs:{"command":"edit"}},[_vm._v(_vm._s(_vm.$t('edit')))]):_vm._e(),(
                  (!data.children || (data.children && !data.children.length)) &&
                    (!data.spList || (data.spList && !data.spList.length)) &&
                    _vm.btnShow &&
                    _vm.btnShow.delete
                )?_c('el-dropdown-item',{attrs:{"command":"delete"}},[_vm._v(_vm._s(_vm.$t('delete')))]):_vm._e()],1)],1)],1)],1)}}])}),_c('div',{staticClass:"table-wrap"},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.tableLoading),expression:"tableLoading"}],attrs:{"data":_vm.tableData,"border":"","height":"100%"}},[_c('el-table-column',{attrs:{"show-overflow-tooltip":"","prop":"spId","label":_vm.$t('serviceManagement.storeId')}}),_c('el-table-column',{attrs:{"show-overflow-tooltip":"","prop":"name","label":_vm.$t('serviceManagement.storeName')}}),_c('el-table-column',{attrs:{"show-overflow-tooltip":"","prop":"orgName","label":_vm.$t('organManagement.organ')}})],1)],1)],1),(_vm.visible)?_c('AddModal',{attrs:{"parentId":_vm.parentId,"currentItem":_vm.currentItem,"visible":_vm.visible},on:{"getData":_vm.replaceData,"update:visible":function($event){_vm.visible=$event}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }