<template>
  <page>
    <div slot="headerLeft">
      <el-input
        :placeholder="$t('serviceRoleManagement.roleName')"
        v-model.trim="params.roleName"
        @keyup.enter.native="replaceData"
      />
      <el-button type="primary" plain @click="replaceData">{{ $t('query') }}</el-button>
    </div>
    <div slot="headerRight">
      <el-button
        v-if="btnShow && btnShow.add"
        type="primary"
        @click="
          visible = true
          currentItem = null
        "
        icon="el-icon-plus"
        >{{ $t('newadd') }}</el-button
      >
    </div>

    <div class="table-wrap">
      <el-table :data="tableData" border v-loading="loading" height="100%">
        <el-table-column show-overflow-tooltip width="70" type="index" :label="$t('index')" />
        <el-table-column
          show-overflow-tooltip
          prop="roleName"
          :label="$t('serviceRoleManagement.roleName')"
        />
        <el-table-column
          show-overflow-tooltip
          prop="roleDesc"
          :label="$t('serviceRoleManagement.roleInfo')"
        />

        <el-table-column width="220" :label="$t('operation')">
          <template slot-scope="{ row }">
            <div class="operateRow">
              <el-button
                v-if="btnShow && btnShow.assign"
                type="text"
                @click="
                  currentItem = row
                  assignAuthorityVisible = true
                "
                >{{ $t('serviceRoleManagement.assignPermiss') }}</el-button
              >
              <el-button
                v-if="btnShow && btnShow.edit && row.remark !== 'default'"
                type="text"
                @click="editHandler(row)"
                >{{ $t('edit') }}</el-button
              >
              <el-button
                v-if="btnShow && btnShow.delete && row.remark !== 'default'"
                type="text"
                @click="delHandler(row.roleId)"
                >{{ $t('delete') }}</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <pagination
      slot="pagination"
      :total="total"
      :page-size.sync="params.pageSize"
      :page.sync="params.currentPage"
      @change="getData"
    />
    <add-role-modal
      @getData="replaceData"
      :currentItem="currentItem"
      :visible.sync="visible"
      v-if="visible"
    />
    <assign-authority-modal
      @getData="replaceData"
      :currentItem="currentItem"
      :visible.sync="assignAuthorityVisible"
      v-if="assignAuthorityVisible"
    />
  </page>
</template>

<script>
import watchParamsGetData from '@/mixins/watchParamsGetData'
import AddRoleModal from './components/AddRoleModal'
import AssignAuthorityModal from './components/AssignAuthorityModal'

export default {
  mixins: [watchParamsGetData],
  components: {
    AddRoleModal,
    AssignAuthorityModal
  },
  data() {
    return {
      loading: false,
      visible: false,
      currentItem: null,
      tableData: [],
      assignAuthorityVisible: false
    }
  },
  created() {},
  computed: {
    currentSpId() {
      return sessionStorage.getItem('spId')
    }
  },
  methods: {
    async getData(loading = true) {
      loading ? (this.loading = true) : ''
      const data = this.params
      data.system = 2
      try {
        const res = await this.$api.systemManagement.roleList(data)

        if (res.result && res.result.list) {
          this.tableData = (res.result.list || []).map(item => {
            return item
          })
          this.total = res.result.total
        } else {
          this.tableData = []
          this.total = 0
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    editHandler(row) {
      this.currentItem = row
      this.visible = true
    },
    async delHandler(roleId) {
      try {
        await this.$confirm(this.$t('serviceRoleManagement.deleteThisRole'), this.$t('tips'), {
          confirmButtonText: this.$t('confirm'),
          cancelButtonText: this.$t('cancel')
        })
        this.loading = true
        await this.$api.systemManagement.roleDel([roleId])
        this.$message.success(this.$t('deleteSuccess'))
        this.getData(false)
      } catch (err) {
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss"></style>
