//客户账号管理（暂时没用）
<template>
  <page>
    <div slot="headerLeft">
      <el-input
        :placeholder="$t('deviceList.customer')"
        v-model.trim="params.spName"
        @keyup.enter.native="replaceData"
        clearable
      />
      <el-button type="primary" plain @click="replaceData">{{ $t('query') }}</el-button>
      <el-button v-show="multipleSelection.length" type="danger" plain @click="handlerDel(true)">{{
        $t('delete')
      }}</el-button>
    </div>
    <div slot="headerRight">
      <el-button
        v-if="btnShow && btnShow.add"
        type="primary"
        @click="
          visible = true
          currentItem = null
        "
        icon="el-icon-plus"
        >{{ $t('newadd') }}</el-button
      >
    </div>

    <div class="table-wrap">
      <el-table
        @selection-change="handleSelectionChange"
        @expand-change="expandChange"
        :row-key="getRowKeys"
        :expand-row-keys="expands"
        :data="tableData"
        border
        v-loading="loading"
        height="100%"
      >
        <el-table-column v-if="btnShow && btnShow.delete" type="selection" width="55" />
        <el-table-column type="index" width="100" :label="$t('index')" />
        <el-table-column
          show-overflow-tooltip
          prop="accountCode"
          :label="$t('serviceAccountManagement.loginAccount')"
        />
        <el-table-column show-overflow-tooltip prop="spName" :label="$t('deviceList.customer')" />
        <el-table-column
          show-overflow-tooltip
          prop="roleName"
          :label="$t('serviceAccountManagement.role')"
        />
        <el-table-column width="100" :label="$t('serviceAccountManagement.accountStatus')">
          <template slot-scope="{ row }">
            <el-switch v-model="row.status" @change="statusUpdate(row)" />
          </template>
        </el-table-column>

        <el-table-column width="300" :label="$t('operation')">
          <template slot-scope="{ row, $index }">
            <div class="operateRow">
              <el-button
                v-if="btnShow && btnShow.childList"
                type="text"
                @click="toggleRowExpansion(row, $index)"
                >{{
                  row.showChildList
                    ? $t('serviceAccountManagement.collapseList')
                    : $t('serviceAccountManagement.subAccountList')
                }}</el-button
              >
              <el-button
                v-if="btnShow && btnShow.changePassword"
                type="text"
                @click="
                  currentItem = row
                  modifyPwdVisible = true
                "
                >{{ $t('changePassword') }}</el-button
              >
              <el-button v-if="btnShow && btnShow.edit" type="text" @click="editHandler(row)">{{
                $t('edit')
              }}</el-button>
              <el-button
                v-if="btnShow && btnShow.delete"
                type="text"
                @click="handlerDel(false, row.id)"
                >{{ $t('delete') }}</el-button
              >
            </div>
          </template>
        </el-table-column>

        <el-table-column v-if="btnShow && btnShow.childList" type="expand">
          <template slot-scope="{ row }">
            <div class="expandWrap">
              <el-table
                v-loading="row.loading"
                size="small"
                :data="row.expandData"
                border
                :height="200"
              >
                <el-table-column
                  show-overflow-tooltip
                  prop="name"
                  :label="$t('serviceAccountManagement.loginName')"
                />
                <el-table-column
                  show-overflow-tooltip
                  prop="accountCode"
                  :label="$t('serviceAccountManagement.loginAccount')"
                />
                <el-table-column
                  show-overflow-tooltip
                  prop="roleName"
                  :label="$t('serviceAccountManagement.role')"
                />

                <el-table-column width="100" :label="$t('serviceAccountManagement.accountStatus')">
                  <template slot-scope="{ row }">
                    <el-switch v-model="row.status" @change="statusUpdate(row)" />
                  </template>
                </el-table-column>

                <el-table-column :label="$t('operation')">
                  <template slot-scope="scope">
                    <div class="operateRow">
                      <el-button
                        v-if="btnShow && btnShow.changePassword"
                        type="text"
                        @click="
                          currentItem = scope.row
                          modifyPwdVisible = true
                        "
                        >{{ $t('changePassword') }}</el-button
                      >
                      <el-button type="text" @click="editHandler(scope.row, true)">{{
                        $t('edit')
                      }}</el-button>
                      <el-button type="text" @click="handlerDel(false, scope.row.id, row)">{{
                        $t('delete')
                      }}</el-button>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination
                small
                layout="prev, pager, next"
                @current-change="
                  page => {
                    renderExpandChildData(row, page)
                  }
                "
                :total="row.expandDataTotal"
              />
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <pagination
      slot="pagination"
      :total="total"
      :page-size.sync="params.pageSize"
      :page.sync="params.currentPage"
      @change="getData"
    />
    <add-account-modal
      @getData="replaceData"
      :serviceList="serviceList"
      :currentItem="currentItem"
      :isChild="isChild"
      :visible.sync="visible"
      v-if="visible"
    />
    <ModifyPwdModal
      @getData="replaceData"
      :currentItem="currentItem"
      :visible.sync="modifyPwdVisible"
      v-if="modifyPwdVisible"
    />
  </page>
</template>

<script>
import watchParamsGetData from '@/mixins/watchParamsGetData'
import AddAccountModal from './components/AddAccountModal'
import ModifyPwdModal from './components/ModifyPwdModal'

export default {
  mixins: [watchParamsGetData],
  components: {
    AddAccountModal,
    ModifyPwdModal
  },
  data() {
    return {
      firstIn: true,
      visible: false,
      isChild: false,
      modifyPwdVisible: false,
      currentItem: {},
      tableData: [],
      expands: [],
      multipleSelection: []
    }
  },

  created() {},

  methods: {
    async getSelectData() {
      let res = await Promise.all([this.$api.systemManagement.spCanInsertAccountList()])

      this.serviceList = res[0].result.map(item => {
        return {
          label: item.name,
          value: item.spId
        }
      })
    },
    async getData(loading = true) {
      loading ? (this.loading = true) : ''
      if (this.firstIn) {
        try {
          await this.getSelectData()
        } catch (e) {
          console.log(e)
        } finally {
          this.firstIn = false
          this.renderData()
        }
      } else {
        this.renderData()
      }
    },
    async renderData() {
      try {
        const data = this.params
        data.accountType = 2
        const res = await this.$api.systemManagement.userList(data)

        if (res.result) {
          this.tableData = ((res.result && res.result.list) || []).map(item => {
            if (item.roleList && item.roleList.length) {
              item.roleName = item.roleList[0] && item.roleList[0].roleName
              item.roleId = item.roleList[0] && item.roleList[0].roleId
            }

            item.status = !item.status
            item.expandData = []
            item.loading = false
            item.expandDataTotal = 0
            return item
          })
          this.total = res.result.total
        } else {
          this.tableData = []
          this.total = 0
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.firstIn = false
        this.loading = false
      }
    },

    getAccountType(type) {
      let accountTypeTxt = ''
      switch (type) {
        case 0:
          accountTypeTxt = this.$t('serviceAccountManagement.admin')
          break
        case 1:
          accountTypeTxt = this.$t('serviceAccountManagement.operator')
          break
        case 2:
          accountTypeTxt = this.$t('serviceAccountManagement.customerAdmin')
          break
        case 3:
          accountTypeTxt = this.$t('serviceAccountManagement.customerOperator')
          break
      }
      return accountTypeTxt
    },

    getRowKeys(row) {
      return row.id
    },

    expandChange(row, array) {
      if (array.length) {
        this.expands = [row.id]
        this.renderExpandChildData(row, 1, true)
      }
      row.showChildList = array.length
    },
    async renderExpandChildData(row, page, firstIn) {
      try {
        row.loading = true
        const res = await this.$api.systemManagement.userList({
          spId: row.spId,
          accountType: 3,
          page: page
        })

        if (firstIn) {
          row.expandDataTotal = res.result.total
        }

        row.expandData = ((res.result && res.result.list) || []).map(item => {
          item.status = !item.status

          if (item.roleList && item.roleList.length) {
            item.roleName = item.roleList[0] && item.roleList[0].roleName
          }

          return item
        })
      } catch (e) {
        console.log(e)
      } finally {
        row.loading = false
      }
    },
    async toggleRowExpansion(row, index) {
      const _this = this
      if (!row.showChildList) {
        this.tableData.forEach(_ => {
          _.showChildList = false
        })
      }

      row.showChildList = !row.showChildList

      if (!row.showChildList) {
        this.expands = []
      } else {
        this.expands = [row.id]
        this.renderExpandChildData(row, 1, true)
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val.map(item => item.id)
    },

    editHandler(row, isChild) {
      this.isChild = isChild || false
      this.currentItem = row
      this.visible = true
    },

    async statusUpdate(row) {
      this.loading = true

      try {
        await this.$api.systemManagement.userUpdate({ id: row.id, status: row.status ? 0 : 1 })
      } catch (e) {
        row.status = !row.status
      } finally {
        this.loading = false
      }
    },
    async handlerDel(delMore, id, row = null) {
      try {
        await this.$confirm(
          delMore
            ? this.$t('serviceAccountManagement.deleteSelectedAccount')
            : this.$t('serviceAccountManagement.deleteThisAccount'),
          this.$t('tips'),
          {
            confirmButtonText: this.$t('confirm'),
            cancelButtonText: this.$t('cancel')
          }
        )
        this.loading = true
        try {
          await this.$api.systemManagement.userDelete(delMore ? this.multipleSelection : [id])
          this.$message.success(this.$t('deleteSuccess'))
          await this.getData(false)

          if (row) {
            row.showChildList = false
            this.expands = []
            // this.renderExpandChildData(row, 1, true)
            // console.log('tableData', this.tableData)
          }
        } catch (e) {
          console.log(e)
        } finally {
          this.loading = false
        }
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.expandWrap {
  ::v-deep .el-table th,
  .el-table tr {
    background-color: #f5f7fa;
  }

  .el-pagination {
    text-align: right;
  }
}
</style>
